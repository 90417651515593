import { ApiHttpService } from '@abbadox-monorepo/core-http-client';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingHttpService {
  private readonly apiHttpService = inject(ApiHttpService);

  submitPayment(): Observable<any> {
    return of(true);
  }
}
