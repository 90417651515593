import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { BillingApiActions } from './billing.state';
import { BillingHttpService } from './services/billing-http-client.service';

/** Perform a payment to finalize the check-in process. */
export const submitPayment$ = createEffect(
  (actions$ = inject(Actions), billingHttpService = inject(BillingHttpService)) =>
    actions$.pipe(
      ofType(BillingApiActions.submitPaymentAttempted),
      switchMap(() =>
        billingHttpService.submitPayment().pipe(
          map(() => BillingApiActions.submitPaymentSuccess()),
          catchError((error) => of(BillingApiActions.submitPaymentFailed({ error }))),
        ),
      ),
    ),
  { functional: true },
);
